
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { ChartData, ChartOptions } from 'chart.js';

import METRICS_SCORE from '@/modules/score/constants/metrics-score.constant';

import ScoreGraphLabels from '@/modules/home/components/score/score-graph-labels.vue';
import ComparedScoreDocumentFilter from '@/modules/home/components/score/compared-score-document-filter.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import ScoreGraphTooltip from '@/modules/home/components/score/score-graph-tooltip.vue';

import ClusterScoresService, { ClusterScoresServiceS } from '../../modules/score/score.service';

type GraphData = {
    ciScore: (number | null)[],
    minScore: (number | null)[],
    maxScore: (number | null)[],
    labels: string[]
} | null;

@Component({
    components: {
        CustomGraph,
        ComparedScoreDocumentFilter,
        ScoreGraphLabels,
        LoaderWrapper,
        ScoreGraphTooltip,
    },
})
export default class ScoreGraphSection extends Vue {
    @Inject(ClusterScoresServiceS) clusterScoresService!: ClusterScoresService;

    activeMetric: METRICS_SCORE = METRICS_SCORE.MONTH;

    get metrics() {
        return $enum(METRICS_SCORE).map(value => ({
            value,
            name: this.$t(`score.metricsScore.${value}`) as string,
        }));
    }

    get skeleton() {
        return this.clusterScoresService.storeState.loading.isLoading();
    }

    selectRange(metric: METRICS_SCORE) {
        this.activeMetric = metric;
    }

    getGraphData(metric: METRICS_SCORE) : GraphData {
        const { scoreStatisticsHistory } = this.clusterScoresService;
        if (!scoreStatisticsHistory) {
            return null;
        }
        const ciScore: (number | null)[] = [];
        const minScore: (number | null)[] = [];
        const maxScore: (number | null)[] = [];
        const labels: string[] = [];

        const allDates = this.clusterScoresService.getDatesInRange();
        const dates = this.clusterScoresService.filterDates(allDates, metric);

        dates.forEach((date: string) => {
            const item = scoreStatisticsHistory[date];
            ciScore.push(item ? item.score : null);
            minScore.push(item ? item.minScore : null);
            maxScore.push(item ? item.maxScore : null);
            labels.push(date);
        });
        return {
            ciScore,
            minScore,
            maxScore,
            labels,
        };
    }

    get chartData(): ChartData {
        if (!this.clusterScoresService.scoreStatisticsHistory) {
            return {
                datasets: [],
                labels: [],
            };
        }
        const data = this.getGraphData(this.activeMetric);

        if (!data) {
            return {
                datasets: [],
                labels: [],
            };
        }

        return {
            labels: data.labels,
            datasets: [
                {
                    data: data.ciScore,
                    backgroundColor: '#2081AC',
                    borderColor: '#2081AC',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    fill: false,
                    label: 'Rate Value Score',
                },
                {
                    data: data.minScore,
                    fill: 'origin',
                    backgroundColor: '#79CFF3',
                    borderDash: [0, 1],
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    label: 'Chain Min Score',
                },
                {
                    data: data.maxScore,
                    fill: '1',
                    backgroundColor: '#79CFF3',
                    borderDash: [0, 1],
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    label: 'Chain Max Score',
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            hover: {
                intersect: false,
            },
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                    hoverRadius: 4,
                },
            },
            // @ts-ignore
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        maxTicksLimit: 10,
                        autoSkip: true,
                        callback: value => {
                            const [year, month, day] = String(value).split('-');
                            return `${day}/${month}/${year.toString().substr(-2)}`;
                        },
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        maxTicksLimit: 5,
                        autoSkip: true,
                        stepSize: 1,
                        min: 0,
                        max: 100,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }
}
